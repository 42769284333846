@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";
@import "assets/flags/flags.css";

.custom-spinner .p-progress-spinner-circle {
    animation: custom-progress-spinner-dash 1.5s ease-in-out infinite,
    custom-progress-spinner-color 6s ease-in-out infinite;
}

@keyframes custom-progress-spinner-color {
    100%,
    0% {
        stroke: #FFFFFF;
    }
    40% {
        stroke: #000000;
    }
    66% {
        stroke: #FFFFFF;
    }
    80%,
    90% {
        stroke: #000000;
    }
}

@keyframes custom-progress-spinner-dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

.disabled-row {
    background-color: #9e9e9e3d !important;
}

.status-normal {
    background-color: #bdd8b7;
    box-sizing: border-box;
    color: #0f9e3b;
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
    max-width: 100%;
    text-transform: uppercase;
    vertical-align: baseline;
    border-radius: 3px;
    padding: 2px 3px 3px;
}

.status-sng {
    background-color: rgb(177, 224, 230);
    box-sizing: border-box;
    color: #007ad9;
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
    max-width: 100%;
    text-transform: uppercase;
    vertical-align: baseline;
    border-radius: 3px;
    padding: 2px 3px 3px;
}

.status-demo {
    background-color: rgb(228, 230, 150);
    box-sizing: border-box;
    color: #ffad07;
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
    max-width: 100%;
    text-transform: uppercase;
    vertical-align: baseline;
    border-radius: 3px;
    padding: 2px 3px 3px;
}

.status-approve {
    background-color: rgb(255, 235, 230);
    box-sizing: border-box;
    color: rgb(191, 38, 0);
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
    max-width: 100%;
    text-transform: uppercase;
    vertical-align: baseline;
    border-radius: 3px;
    padding: 2px 3px 3px;
}

.dropdown-custom-lbl {
    float: left;
    width: 85%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.dropdown-custom-short-lbl {
    float: left;
    width: 75%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.dropdown-custom-value {
    float: right;
    color: lightgray;
}

.stacked-label {
    font-size: 12px;
    color: gray;
}

.stacked-label-switch {
    vertical-align: super;
    font-size: 13px;
    color: black;
}

.p-inputswitch-red .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: rgb(191, 38, 0);
}

.p-inputswitch-red .p-inputswitch.p-inputswitch-checked:not(.ui-state-disabled):hover .p-inputswitch-slider {
    background-color: rgb(191, 38, 0);
}

.p-inputswitch-red .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider {
    background: rgb(191, 38, 0);
}

.p-inputswitch-green .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: #20d077;
}

.p-inputswitch-green .p-inputswitch.p-inputswitch-checked:not(.ui-state-disabled):hover .p-inputswitch-slider {
    background-color: #20d077;
}

.p-inputswitch-green .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider {
    background: #20d077;
}

.field-word-wrap {
    white-space: -moz-pre-wrap !important;
    word-wrap: break-word;
    white-space: -o-pre-wrap;
    word-break: break-all;
    white-space: normal;
}

.p-tooltip {
    max-width: 50em;
}

.labeled-text {
    border-bottom: 1px solid rgb(228, 230, 235);
}

.labeled-text label {
    display: block;
    font-size: 10px;
    color: gray;
}

.stacked-label {
    font-size: 12px;
    color: gray;
}

.display-hidden {
    display: none;
}

.p-toolbar-group-left {
    justify-content: flex-start;
}

.p-toolbar-group-right {
    justify-content: flex-end;
}

.flex-between {
    display: flex;
    justify-content: space-between;
}

.dialog-footer-ban {
    display: flex;
    justify-content: space-between;
}
.table {
    th {
        white-space: nowrap;
    }

    td {
        white-space: nowrap;
    }

    &__filter-link {
        font-family: monospace;
        font-size: 12px;
        color: #9e9e9e;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &__timestamp-item {
        width: 11.5rem;
    }
}

.p-toolbar-group-right-custom {
    display: flex;
    align-items: flex-end;
}

.overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
}
.p-scroller {
    min-height: 50px;
}

.p-scroller-content {
    width: 100%;
}

.p-paginator-left-content {
    margin-right: 0 !important;
}

.display-flex {
    display: flex;
}
